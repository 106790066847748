import React from 'react';
import Container from 'react-bootstrap/Container';
import { Parallax} from 'react-parallax';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import hero4 from "./media/hero4.webp"; // Correct path to the image
import parallax4 from "./media/parallax4.webp"; // Correct path to the image
import parallax3 from "./media/parallax3.webp"; // Correct path to the image


function Home() {
  return (
		
		
		
		<div className="Container fluid">
		   <img src={hero4} alt="Hero 4" className="img-fluid d-block w-100" />
					  <div className="text-box1">
								<Container>
										<h1>”Passionate About People”</h1>
            <p>
         As someone who is passionate about people, the journey of life and growth, it was a natural choice to choose a job where I could work with people. I feel inspired by my work and being able to journey with people through the landscape of their lives. As a psychologist I assist people in opening up new possibilities, creating a deeper understanding of themselves in order to move towards greater integration and self-acceptance.
									</p>
									<p>I started my own practice in the Northern suburbs of Cape Town (2005-2012) and worked part-time at Child and Family Guidance Clinic (until 2006) and Kaleidoscope Child and Family Clinic (until 2009) I have considerable experience in child, adolescent and family psychotherapy and have a special interest in Couples therapy. My additional training in Imago Relationship Therapy helps me to facilitate couples in a new way of being, deepening the connection, and optimizing the healing and growth potential of their relationship. As is the case with many practicing psychologists today, I integrate a number of theoretical approaches into my work to adapt to broad range of individuals I work with.
									</p>
									    </Container>
			       	</div>		
											
										<div>
           <Parallax
             blur={0}
             bgImage={parallax4}
             bgImageAlt="parallax"
             strength={600}
           >
      <div style={{ height: '600px' }} />
    </Parallax>
		</div>
											
											

									 <div className="text-box1">
							    	<Container>
									<p>I hold a Masters (Cum Laude) degree in Counselling Psychology / MTH (Stellenbosch, 2005; 2001) and is a registered Counselling Psychologist with the Health Professions Council of South Africa (PS 0090603) and the Board of Health Care Funders of South Africa (PR 0182826).
									</p>
									<p>I completed my internship at three different institutions, namely, TC Newman Hospital Paarl: Psychiatric Ward, Franschhoek Clinic and the Centre for Student Counselling at the University of Stellenbosch (2004). I provided psychotherapy, group work, psychometric assessments as well as career guidance. My Research article: Resilience in remarried families” was published in the American Journal of Family Psychology in 2009. I also lectured at Stellenbosch University (2002-2010) in Group Work, Intake Interview, Psychopathology, Cognitive Behavioural Therapy, Person-Centred Therapy and Personality Theory (Theology Department).</p>
      </Container>
				</div>		
		
					
			<div>
    <Parallax
      blur={0}
      bgImage={parallax3}
      bgImageAlt="parallax"
      strength={600}
    >
      <div style={{ height: '600px' }} />
    </Parallax>
		</div>
					
					
					
						

						

						

  


			</div>	
     );
			}

export default Home;