import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { Parallax} from 'react-parallax';
import parallax from "./media/parallax2.webp"; // Correct path to the image
import hero3 from "./media/hero3.webp"; // Correct path to the image

const ContactForm = () => {


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isMessageSent, setIsMessageSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      to_email: 'rgpmoen@gmail.com', // replace with the recipient's email address
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs.send('service_i5k0h1e', 'template_tz33v0a', templateParams, 'wcOD4M4Gk0kaHAQ3u')
      .then((response) => {
        console.log('Email sent successfully:', response);
        setIsMessageSent(true);
      })
      .catch((error) => {
        console.error('Email failed to send:', error);
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (


		
    <div className=" formulier Container fluid" >
				
					   <img
        src={hero3}
        alt="Hero 3"
        className="img-fluid d-block w-100"
      />

				<h1>Contact Form</h1>
				<p>Please use this form to send me a message. I will contact you shortly</p><hr/><br/>
      <form onSubmit={handleSubmit}>
        <label className="label1">
          <p>Name:</p>
          <input type="text" name="name" onChange={handleChange} />
        </label>
        <br />
        <label className="label1">
          <p>Email:</p> 
          <input type="email" name="email" onChange={handleChange} />
        </label >
        <br />
        <label className="label1">
          <p>Message:</p>
          <textarea name="message" onChange={handleChange}></textarea>
        </label>
        <br />
        <button type="submit" className="btn btn-success">Submit</button>
      </form>
      {isMessageSent && <p>Your message has been sent!</p>}
						
						<br></br>
				<div>
    <Parallax
      blur={0}
      bgImage={parallax}
      bgImageAlt="parallax"
      strength={600}
    >
      <div style={{ height: '600px' }} />
    </Parallax>
		</div>
						
						
    </div>
  );
};

export default ContactForm;
