// src/App.js
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './style.css';
import Navbar from "./Navbar";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";


function App() {
  return (
   <BrowserRouter basename="">
			
						<div id="logo"><center><h2>- Carien&nbsp;&nbsp;du&nbsp;&nbsp;Toit -</h2>
					<h3>Counselling Psychologist</h3>
             MA. Couns. Psych/MTh. (US)					
					</center></div>
	


        <Navbar />
        <Routes>
        <Route path="/" element={<Home />} />
		<Route path="/about" element={<About />} />
		<Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
       </Routes>
   


<footer className="page-footer font-small blue pt-4" id="footer">
    <div className="container text-md-left">
        <div className="row">
            <div className="col-md-6 mt-md-0 mt-3">
                <h5 className="text-uppercase"><strong>Contact Us</strong></h5>
                <h5>Unit 1B Mondi House</h5>
																<h5>380 Old Howick Road</h5>
																<h5>Hilton 3245 | KwaZulu Natal</h5>
																<p>Phone: +27 (0)33-3434409</p>
            </div>

            <hr className="clearfix w-100 d-md-none pb-0"/>

            <div className="col-md-3 mb-md-0 mb-3">
												   <h5><strong>Menu</strong></h5>
												     <ul className="list-unstyled">
                    <li className="nav-item"><Link className="nav-link" to="/"><p>Home</p></Link></li>
                    <li className="nav-item"><Link className="nav-link" to="/about"><p>About</p></Link></li>     
                    <li className="nav-item"><Link className="nav-link" to="/services"><p>Services</p></Link></li>
                    <li className="nav-item"><Link className="nav-link" to="/contact"><p>Contact</p></Link></li>                           
                </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
												  <h5><strong>Find us on Social Media</strong></h5>
                <ul className="list-unstyled">
                    <li><a href="https://www.facebook.com/cariendt/"><h5>Facebook</h5></a></li>
                 </ul>
            </div>
        </div>
    </div>

    <div className="footer-copyright text-center py-3"><hr></hr><p><strong>© 2024 </strong>
        <strong> Carien Du Toit | South Africa</strong></p>
    </div>

</footer>
					
					
					
 </BrowserRouter>

  );
}

export default App;
