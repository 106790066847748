import React from 'react';
import { Parallax} from 'react-parallax';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import hero5 from "./media/hero5.webp"; // Correct path to the image
import parallax5 from "./media/parallax5.webp"; // Correct path to the image
import aim from "./media/aim.webp"; // Correct path to the image
import marriage from "./media/marriage.webp"; // Correct path to the image
import couple from "./media/couple.webp"; // Correct path to the image
import family from "./media/family.webp"; // Correct path to the image




function Services() {
  return (
		<div className="Container ">
		   <img src={hero5} alt="Hero 5" className="img-fluid d-block w-100" />
					  <div className="text-box1">

										<div className="services-quote">
										<p className="mb-2 text-center">
										”Problems are a part of life.<br/>
           It is not the presence of problems that determines our health and well-being,<br/>
           but what we do when we encounter them”</p>
           </div>	
											
											<div className="container space">
											<h1>”Therapy Sessions”</h1>
											</div>

									<div className="cards">
									<MDBRow className='row-cols-1 row-cols-md-2 g-4'>
															<MDBCol>
																	<MDBCard>
																			<MDBCardImage
																					src={aim}
																					alt='aim'
																					position='top'
																			/>
          <MDBCardBody>
            <MDBCardTitle ><strong >The Aim Of Therapy</strong></MDBCardTitle><br />
            <MDBCardText>
              <p>The aim of therapy is to resolve the issues that brought you to counselling and to help you establish or re-establish your life and grow beyond negative patterns from the past. How therapy is conducted is determined by the nature of the difficulty one is experiencing. It is also influenced by age and stage of development. I offer individual therapy for teenagers and adults on a long- or short-term basis. Individual session: 50 minutes.</p>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={marriage}
            alt='mariage'
            position='top'
          />
										
										
          <MDBCardBody>
            <MDBCardTitle><strong>Pre-Marital Course</strong></MDBCardTitle><br />
            <MDBCardText>
              All too many couples get caught up in spending time planning their weddings without developing a plan for their marriage. The early stages of a relationship, when the feelings you have for your partner are usually positive, is the ideal time to become more conscious and intentional in the ways you interact and work together. Pre-marital course is 6 sessions of 60 min.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={couple}
            alt='couple'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle><strong>Imago Couples Therapy</strong></MDBCardTitle><br/>
            <MDBCardText>
              At some point in a relationship, couples often find themselves struggling with anger and shock, despair and sadness.  Even if life at home is relatively peaceful, couples lament that they have “nothing in common anymore.” And so they lead a disappointed or angry co-existence, each with their own friends and interests, in a marriage of convenience, or an arrangement they endure “for the sake of the children.” Imago Couples Therapy assists couples in developing conscious, connecting, intimate, and committed relationships. Couples session: 50-75 minutes.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={family}
            alt='family'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle><strong>Family session</strong></MDBCardTitle><br/>
            <MDBCardText>
              Therapy focuses on addressing and modifying the obstacles which exist in the family system, helping family members communicate with one another in a productive way, and in helping them to working together toward more effective problem resolution. Family session: 50-75 minutes.<br/><br/>
														<MDBCardTitle ><center><strong >In-hospital services</strong></center></MDBCardTitle><br />
														 <MDBCardText>
              <p>	I provide in-hospital services in cases where people are hospitalised and in need of a therapist. Session: 75 minutes.</p>
            </MDBCardText>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
	    </MDBRow>
</div>
	</div>		
											
										<div>
           <Parallax
             blur={0}
             bgImage={parallax5}
             bgImageAlt="parallax"
             strength={600}
           >
      <div style={{ height: '600px' }} />
    </Parallax>
		</div>
	</div>	
     );
			}

export default Services;