import React from 'react';
import ReactAudioPlayer from 'react-audio-player'
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import { Parallax} from 'react-parallax';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import image1 from "./media/slider1.webp"; // Correct path to the image
import image2 from "./media/slider2.webp"; // Correct path to the image
import image3 from "./media/slider3.webp"; // Correct path to the image
import image4 from "./media/slider4.webp"; // Correct path to the image
import image5 from "./media/slider5.webp"; // Correct path to the image
import image6 from "./media/slider6.webp"; // Correct path to the image
import parallax1 from "./media/parallax1.webp"; // Correct path to the image
import groeipyne from './media/groeipyne.ogg'


function Home() {
  return (
		<div className="carousel-fade">
    <Carousel  data-bs-theme="dark">
      <Carousel.Item>
        <img className="d-block w-100" src={image1} alt="First slide"/>				
    </Carousel.Item>
				
      <Carousel.Item>
        <img className="d-block w-100" src={image2} alt="Second slide"/>
      </Carousel.Item>		
						
					 <Carousel.Item>
        <img className="d-block w-100" src={image3} alt="Third slide" />
      </Carousel.Item>		
						
	    <Carousel.Item>
        <img className="d-block w-100" src={image4} alt="Fourth slide" />
      </Carousel.Item>	
						
	    <Carousel.Item>
        <img className="d-block w-100" src={image5} alt="Fifth slide"/>
      </Carousel.Item>				
						
	    <Carousel.Item>
        <img className="d-block w-100" src={image6} alt="Sixth slide"/>
      </Carousel.Item>							
    </Carousel>
				
				
						
					<Container fluid className="text-box1">
								<Container>
        								<center><div className="homequote"><p>“The real voyage of discovery consists not in seeing new landscapes<br/>but in having new eyes”<br/>
             (Marcel Proust)</p></div></center><br/>
									<h1>Welcome!</h1>			
        <p>
         I am a counselling psychologist practicing in Hilton, KZN, South Africa. <br/>
									Some people may find it difficult to take the first step towards getting psychological help.<br/>Maybe you? You are not alone.... More people are consulting with psychologists to help with personal growth, increase their sense of well being and all they can be in their personal, interpersonal and occupational areas of their lives.
									</p>
									<p>In my practice I consult with people of all age groups about a range of problems. I also consult with couples, families and people experiencing difficulties in their work relationships. I provide psychological services in both English and Afrikaans and I adapt treatment to suit the needs of each person.
									</p>
									<p>I believe therapy is a journey of collaboration, consciousness, identifying patterns, and facilitate change to having more freedom and a meaningful life.
									</p>
									<p>The information on my website is put together in such a way to assist you in getting the help for yourself or for someone you care for.</p>
      </Container>
					</Container>	
					
			<div>
    <Parallax
      blur={0}
      bgImage={parallax1}
      bgImageAlt="parallax"
      strength={600}
    >
      <div style={{ height: '600px' }} />
    </Parallax>
		</div>
					
					
					
						

						
											<Container fluid className="text-box2">
								<Container className="audio">
              
																<h1>Groeipyne 6 September 2018</h1><br></br>
																<p><strong>Met Johan van Lill, Radio Sonder Grense (RSG)</strong></p>
																<p>Johan van Lill praat met voorligting- en pastorale sielkundige Carien du Toit oor hoe werkende ouers steeds ’n suksesvolle verhouding met hulle kinders kan opbou ten spyte van beperkte kontaktyd.</p>
																<p>Luister hier na die volledige gesprek:</p><br></br>
																
																<ReactAudioPlayer
																		src={groeipyne}
																		controls
															

                 />
   
						      </Container>
						</Container>	
						

  


			</div>	
     );
			}

export default Home;